<template>
<div>
    <v-data-table v-bind:headers="headers" :items="items" item-key="tsk_id" :search="search" v-model="taskSelected" show-select
        items-per-page-text="Lignes par page" :items-per-page-options="pagination.rowsPerItem" class="ma-4 rounded-lg elevation-2">
        <template v-slot:top>
            <v-row class="rounded-lg ma-0 pa-0" style="background-color: #FAFAFA">
                <v-col cols="6" md="6" class="pa-1">
                <v-text-field append-icon="mdi-magnify" label="Recherche" outlined dense clearable single-line
                            hide-details v-model="search" @blur="saveSearchToCache()" class="pa-2 rounded-xl">
                </v-text-field>
                </v-col>
                <v-col cols="6" md="6" class="text-right pa-1">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn v-show="taskSelected.length" @click="massEditDialog = true"
                                v-on="on" color="orange" outlined :loading="loading.getXls" class="ma-2">
                                <v-icon dark>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <span>Modifier en masse</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" color="success" outlined :loading="loading.getXls" class="ma-2" @click="downloadTasks()">
                                <v-icon dark>mdi-file-excel</v-icon>
                            </v-btn>
                        </template>
                        <span>Telecharger xlsx</span>
                    </v-tooltip>
                </v-col>
            </v-row>
        </template>
        <template v-slot:[`item.tsk_sar_id`]="{ item }">
            <v-chip small outlined label>{{ item.sar_code }}</v-chip>
            <v-chip small outlined label>{{ item.sar_letter }}</v-chip>
        </template>
        <template v-slot:[`item.tsk_date_plan`]="{ item }">
            <v-chip small outlined label>{{ $date(item.tsk_date_plan).format('DD/MM/YYYY') }}</v-chip>
        </template>
        <template v-slot:[`item.tsk_date_end`]="{ item }">
            <v-chip small outlined label>{{ (item.tsk_date_end) ? $date(item.tsk_date_end).format('DD/MM/YYYY') : '' }}</v-chip>
        </template>
        <template v-slot:[`item.tsk_date_close`]="{ item }">
            <v-chip small outlined label>{{ (item.tsk_date_close) ? $date(item.tsk_date_close).format('DD/MM/YYYY') : '' }}</v-chip>
        </template>
        <template v-slot:[`item.tsk_typ_id_origin`]="{ item }">
            <v-chip small outlined label :color="getCacheType('TSO', item.tsk_typ_id_origin).typ_color">
                {{ getCacheType('TSO', item.tsk_typ_id_origin).typ_name }}
            </v-chip>
        </template>
        <template v-slot:[`item.tsk_tty_id`]="{ item }">
            <v-chip small outlined label :color="item.tty_color">
                {{item.tty_name}}
            </v-chip>
        </template>
        <template v-slot:[`item.tsk_customfields`]="{ item }">
            <span class="d-inline-block text-truncate" style="max-width: 300px;">
                {{ (item.tsk_customfields) ? item.tsk_customfields.tsk_comment : ''}}
            </span>
        </template>
        <template v-slot:[`item.tsk_sta_id`]="{ item }">
            <v-chip small outlined label :color="getCacheState('TSK', item.tsk_sta_id).sta_color">
                {{ getCacheState('TSK', item.tsk_sta_id).sta_label }}
            </v-chip>
        </template>
        <template v-slot:[`item.tsk_id`]="{ item }">
            <v-btn outlined small color="grey" class="ml-2 elevation-1" :href="'/#/tasks/subarea/' + item.tsk_sar_id + '/task/' + item.tsk_id + ''">
                <v-icon left class="mr-2">mdi-pencil</v-icon>Ouvrir
            </v-btn>
        </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
        De {{ pageStart }} à {{ pageStop }}
        </template>
    </v-data-table>
    <v-dialog v-model="massEditDialog" :max-width="600">
        <v-card>
        <v-toolbar dark color="primary" dense text>
            <v-toolbar-title class="white--text">Mofidifcations multiple</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="" @click.native="massEditDialog = !massEditDialog"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
            <v-layout row wrap class="mt-2">
                <v-flex xs6 class="pa-2">
                    <DatePicker label="Plannifier le" :objectForm="formForItems" fieldName='tsk_date_plan'></DatePicker>
                </v-flex>
                <v-flex xs6 class="pa-2">
                    <DatePicker label="Date de cloture" :objectForm.sync="formForItems" 
                        @change="formForItems.tsk_sta_id = 'TSK05'"
                        fieldName='tsk_date_end' title="Cloture si date"></DatePicker>
                </v-flex>
                <v-flex xs12 class="pa-2">
                    <v-select 
                        :dense="fieldStyle.dense" :outlined="fieldStyle.outlined" item-text="sta_label" item-value="sta_id"
                        :items="getCacheState('TSK').filter(sta => sta.sta_visibility === 1)" v-model="formForItems.tsk_sta_id" label="Statut" single-line>
                        <template v-slot:prepend><v-icon :color="getCacheState('TSK', formForItems.tsk_sta_id).sta_color">mdi-file-tree</v-icon></template>
                    </v-select>
                </v-flex>
            </v-layout>
        </v-card-text>
        <v-card-actions class="grey lighten-3">
            <v-spacer></v-spacer>
            <v-btn v-show="(formForItems.tsk_date_plan || formForItems.tsk_date_end) || formForItems.tsk_sta_id == 'TSK07'" 
                :disabled="loading.getXls" :loading="loading.getXls" color="success darken-1"  @click.native="saveTasks()">
                Enregistrer
            </v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import DatePicker from '@/components/ui/DatePicker'
import { cacheGetters } from "@/store/cache.module"
import { tskObj }       from '@/mixins/tsk_obj.js'
import { filterUpdateService, taskUpdateService } from '@/rxjsServices';

export default {
    name: 'site_edit',
    props: [ 'toast' ],
    mixins: [ tskObj ],
    components: {
        DatePicker
    },
    data: function () {
        return {
            loading: { getgetTaskItems: false, getXls: false },
            validFrom: true,
            massEditDialog: false,
            fieldStyle: { outlined: true, dense: true },
            items        : [],
            taskSelected : [],
            selectFields : {},
            actionTask   : [],
            typeState: cacheGetters.getTypes(),
            formForItems: {
                tsk_date_plan: null,
                tsk_date_end: null,
                tsk_sta_id: 'TSK04'
                //tsk_duration: '00:00'
            },
            search: '',
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headers: [
                { text: 'Parcelle',       align: 'left', value: 'tsk_sar_id'},
                { text: 'Planification', align: 'left', value: 'tsk_date_plan'},
                { text: 'fin',            align: 'left', value: 'tsk_date_end'},
                { text: 'Clôture',        align: 'left', value: 'tsk_date_close'},
                { text: 'Type',           align: 'left', value: 'tsk_tty_id'},
                { text: 'Obs.',    align: 'left', value: 'tsk_customfields'},
                { text: 'Origine',        align: 'left', value: 'tsk_typ_id_origin'},
                { text: 'Etat',           align: 'left', value: 'tsk_sta_id'},
                { text: 'Action',         align: 'left', value: 'tsk_id'},
            ],
        }
    },
    created(){
        // modification au niveau du filtre
        this.subscribeFilter = filterUpdateService.getFilter().subscribe(message => {
            if (message) {
                this.getItem()
            }
        })
        this.subscribeTask = taskUpdateService.getTask().subscribe(message => {
            if (message) {
                this.getItem()
            }
        })
    },
    beforeMount: function(){
        this.getItem()
    },
    mounted: function (){ },
    methods: {
        async getItem(){
            this.items = []
            let url    = '?per_page=false&extended=withtasktype,withsubarea'
            let option = cacheGetters.getFilterUrl()
            if( !option.length ){
                return false // if no filter just clear list
            }
            url    += ( option.length ) ? '&' + option : ''
            this.getTaskIndex(false, false, url).then( () => {
                this.items = this.listTask
            })            
        },
        async saveTasks(){
            for(const key in this.taskSelected){
                if( this.formForItems.tsk_date_plan ){
                    this.taskSelected[key].tsk_date_plan = this.formForItems.tsk_date_plan
                }
                if( this.formForItems.tsk_date_end ){
                    this.taskSelected[key].tsk_date_end = this.formForItems.tsk_date_end
                }
                if( this.formForItems.tsk_sta_id ){
                    this.taskSelected[key].tsk_sta_id = this.formForItems.tsk_sta_id
                }
                //this.taskSelected[key].tsk_duration = parseInt(this.taskSelected[key].tsk_duration_h.split(':')[0]) + parseFloat(this.taskSelected[key].tsk_duration_h.split(':')[1] / 60)
                let taskType = this.taskSelected[key]
                if( taskType.tsk_sta_id == 'TSK07' ){
                    await this.deleteTaskDestroy( taskType.tsk_id )
                } else {
                    await this.putTaskStore( taskType )
                }

            }
            this.taskSelected = []
            this.$root.$toast({ color: 'success', text: 'Fiche enregistrée !' })
            this.massEditDialog = false
            this.getItem()
        },
        async downloadTasks(){
            this.loading.getXls = true
            let report    = 'tasks'
            let option    = 'per_page=false&extended=withtasktype,withsubarea,withtasktypeinter,withstate'
            let method    = 'get'
            let fileName  = 'rapport'
            let searchCriteria   = cacheGetters.getFilterUrl()
            if( searchCriteria.length > 2 ){
                searchCriteria += '&' + option
            } else {
                searchCriteria  = option
            }
            this.$http.customRequest({ method: method, url: '/report/' + report + '/?' + searchCriteria, responseType: 'blob' } )
            .then( (response) => {
                this.$download(response.data, fileName + ".xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
                this.loading.getXls = false
            }).catch( (error) => {
                this.$root.$toast({ text: 'Erreur lors de l\'extraction !', error: error })
                this.loading.getXls = false
            })
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState   : cacheGetters.getStates,
    },
    beforeDestroy() {
        this.subscribeFilter.unsubscribe()
        this.subscribeTask.unsubscribe()
    }
}
</script>