<template>
<v-container fluid class="pa-0">
    <PageHeader :contextStyle="contextStyle">
      <!-- <template #right>
        <v-btn small>
          <v-icon small color="success">mdi-file-excel</v-icon>
        </v-btn>
      </template> -->
    </PageHeader>

    <TasksList />

</v-container>
</template>

<script>
import PageHeader from '@/components/ui/PageHeader'
import TasksList from '@/components/tasks/TaskList'

import { cacheGetters, cacheMutations } from "../store/cache.module"
import { tskObj }       from '../mixins/tsk_obj.js'

import { filterUpdateService } from '@/rxjsServices';

export default {
    name: 'tasks',
    props: ['toast', 'contextStyle'],
    mixins: [ tskObj ],
    components: {
        PageHeader,
        TasksList
    },
    data: function () {
        return {
            loading: { getXls: false, getTasks: false },         // Enregistrement en cours...
            items: [],
            enableAdd: true,
            modalEvent : false,
            cacheTab: this.$storage.localStorage.get( this.$APP_COD + "_cache" ),
        }
    },
    computed: {
        pages () {
            if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null ) return 0
            return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
        }
    },
    created(){
        this.subTask = filterUpdateService.getFilter().subscribe(message => {
            if (message) {
                this.getItems()
            }
        })
    },
    mounted: function (){
        this.search = cacheGetters.getSearchBarItem('tasks') || ''
        this.getItems()
    },
    methods: {
        async getItems(){
            this.items = []
        },
        handlePage(index){
            this.pagination.limit = this.pagination.rowsPerPage * (index - 1)
        },
        saveSearchToCache(){
            cacheMutations.setSearchBarItem('tasks', this.search)
        },
        downloadTask(){
            this.loading.getXls = true
            let searchCriteria = this.getTaskFilter()
            this.$http.customRequest({ method: 'get', url: '/report/tasks/' + searchCriteria, responseType: 'blob' } )
            .then( (response) => {
                this.loading.getXls = false
                this.$download(response.data, "fosses.xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
            }).catch( () => { 
                this.loading.getXls = false
            })
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates
    },
    beforeDestroy() {
        this.subTask.unsubscribe()
    },
}
</script>